( function( $ )  {

  setInterval( function() {
    var elementTarget = $( '.container-blocks.is-showing' ),
        childElement = elementTarget.find( '.get-height' );

    elementTarget.each( function(index, element) {
      var newHeight = $( childElement[index] ).outerHeight();

      elementTarget.height( newHeight ).parent().height( newHeight );
    });
  }, 1000);

})( jQuery );