/**
 *
 * @param templateID - ID do template onde está a marcação que será substituida
 * @param nameFile - Nome do arquivo que contém as informações que serão carredas no template
 * @param elementToAppend - Elemento em que será adicionado o conteúdo depois de compilado
 * @param callback - Função para reiniciar algum plugin quando necessário
 */
function renderHandlebars( templateID, nameFile, elementToAppend, callback ) {

		var fileURL = '/handlebars-content/' + nameFile + '.json',
		    htmlTemplate,
		    templateCompile;

	jQuery.getJSON( fileURL, function( data ) {
		htmlTemplate = $( templateID ).html();

		templateCompile = Handlebars.compile( htmlTemplate );
		$( elementToAppend ).append( templateCompile( data ) );

		if ( callback != null && typeof( callback ) == "function" )
			callback();
	});
}

Handlebars.registerHelper("inc", function(value, options)
{
	return parseInt(value) + 1;
});