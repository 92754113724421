( function( $ ) {

	$( document ).on( 'click', '.handle-map-tabs', function( event ) {
		event.preventDefault();

		var $this = $( this )
				, elementTarget = $this.find( 'a' ).attr( 'href' );

		if ( $this.hasClass( 'is-tab-map-active' ) ) {
			return false;
		}
		else {
			$( '.handle-map-tabs' ).removeClass( 'is-tab-map-active' );
			$this.addClass( 'is-tab-map-active' );

			$( '.article-maps-wrapper' ).removeClass( 'is-map-active' );
			$( elementTarget ).addClass( 'is-map-active' );
		}
	});

})( jQuery );