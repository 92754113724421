( function( $ ) {
  if ( $( window ).width() < 1200 ) {
    handlebarsMobile();
    $( 'body' ).addClass( 'loaded-mobile' );
  }
  else if ( $( window ).width() >= 1200 ) {
    handlebarsDesktop();
    $( 'body' ).addClass( 'loaded-desktop' );
  }

  $( window ).resize( function() {
    if ( $( window ).width() < 1200 && !$( 'body' ).hasClass( 'loaded-mobile' ) ) {
      $( 'body' ).addClass( 'loaded-mobile' );
      handlebarsMobile();
    }
    else if ( $( window ).width() >= 1200 && !$( 'body' ).hasClass( 'loaded-desktop' ) ) {
      $( 'body' ).addClass( 'loaded-desktop' );
      handlebarsDesktop();
    }
  });

  function handlebarsMobile() {
    renderHandlebars( $( '#m-section-main' ), 'main/section-main-v2', $( '.m-append-main' ) );

    renderHandlebars( $( '#m-section-telefones' ), 'contact/section-contact-v2', $( '.m-append-telefones' ) );

    renderHandlebars( $( '#m-section-maps' ), 'contact/section-contact-v1', $( '.m-append-maps' ), createMaps );

    renderHandlebars( $( '#m-section-galeria' ), 'section-galeria', $( '.m-append-galeria' ), setTimeout( function() {
      if ( $( window ).width() <= 599 ) {
        var mGaleriaSlider = new Swiper('.gallery-content-wrapper-01', {
          slidesPerColumn: 4,
          slidesPerView: 3,
          spaceBetween: 5,
          slidesPerGroup: 12,
          pagination: '.gallery01-pagination',
          nextButton: '.gallery01-nextButton',
          prevButton: '.gallery01-prevButton'
        });

      } else if ( $( window ).width() > 599 && $( window ).width() < 1199 ) {
        var mGaleriaSlider = new Swiper('.gallery-content-wrapper-01', {
          slidesPerColumn: 3,
          slidesPerView: 4,
          slidesPerGroup: 12,
          spaceBetween: 5,
          pagination: '.gallery01-pagination',
          nextButton: '.gallery01-nextButton',
          prevButton: '.gallery01-prevButton'
        });
      }
      npxOpenFancybox(); }, 1000 ) );

    renderHandlebars( $( '#m-section-depoimentos' ), 'section-depoimentos', $( '.m-append-depoimentos' ), setTimeout( function() {

      if ( $( window ).width() < 600 ) {
        var mTestimonySliderMob = new Swiper ( '.m-testimony-slider', {
          speed: 400,
          spaceBetween: 5,
          slidesPerView: 1,
          pagination: '.m-testimony-pagination'
        });

      } else if ( $( window ).width() >= 600 && $( window ).width() < 1199 ) {
        var mTestimonySliderTab = new Swiper ( '.m-testimony-slider', {
          pagination: '.m-testimony-pagination',
          speed: 400,
          spaceBetween: 5,
          slidesPerView: 2,
          paginationClickable: true
        });
      }
    }, 1000 ));

  }


  function handlebarsDesktop() {
    // Reenderiza o template dos números de telefones no header do desktop
    renderHandlebars( $( '#d-address-header' ), 'address', $( '.d-append-header-address' ) );

    // Reenderiza o template dos números de telefones no header do desktop
    renderHandlebars( $( '#d-phone-header' ), 'address', $( '.d-append-header-phone' ) );

    // Reenderiza o template da seção principal(destaque) do site do modelo 1
    //renderHandlebars( $( '#d-section-main' ), 'main/section-main-v1', $( '.d-append-main' ) );

    // Reenderiza o template da seção principal(destaque) do site do modelo 2
    renderHandlebars( $( '#d-section-main' ), 'main/section-main-v2', $( '.d-append-main' ), setTimeout(function() {
      /**
       * Verifica se a div container do destaque possui a classe que indica que o destaque possui imagem e não um slider
       **/
      if ( $( '.container-main.with-image' ).length ) {
        $( '.container-main' ).append( '<style>.container-main.with-image:before { background-image: url(' + $( '.container-main.with-image .content-main' ).attr( 'data-image' ) + ') }</style>' )
      }

      /**
       * Verifica se existe na marcação se exsite um slider para ser carregado
       **/
      if ( $( '.d-slider-main-v2' ).length ) {
        // Cria o slider de destaque da versão 2
        var dMainSlider = new Swiper ( '.d-slider-main-v2', {
          loop: true,
          speed: 400,
          autoplay: 3500,
          spaceBetween: 10,
          slidesPerView: 1,
          pagination: $ ( '.d-pagination-slider-main-v2' ),
          paginationClickable: true
        });

        // Percorre todos os elementos com a classe slide-main-content e cria o estilo
        // que insere a imagem no background do slide
        $ ( '.slide-main-content' ).each ( function ( index, element ) {
          var slideNum   = "slide-" + index,
              slideImage = $ ( element ).attr ( 'data-image' );
          $ ( element ).append ( '<style>.' + slideNum + ':before { background-image: url(' + slideImage + '); }</style>' )
        } );
      }
    }, 1000));

    // Reenderiza a seção das galerias do desktop
    renderHandlebars( $( '#d-section-galeria' ), 'section-galeria', $( '.d-append-galeria' ), setTimeout( function() {
      var dGallerySlider = new Swiper ( '.gallery-content-wrapper-01', {
        speed: 400,
        spaceBetween: 15,
        slidesPerView: 4,
        slidesPerColumn: 2,
        slidesPerGroup: 4,
        pagination: '.gallery01-pagination',
        paginationClickable: true,
        nextButton: '.gallery01-nextButton',
        prevButton: '.gallery01-prevButton'
      });
      npxOpenFancybox();
    }, 1800 ) );

    // Reenderiza a seção dos depoimentos do desktop
    renderHandlebars( $( '#d-section-depoimentos' ), 'section-depoimentos', $( '.d-append-depoimentos' ), setTimeout( function() {
      var dTestimonySlider = new Swiper ( '.d-testimony-slider', {
        speed: 400,
        spaceBetween: 5,
        slidesPerView: 3
      });
    }, 1000 ) );

    // Reenderiza a seção do mapa para o desktop do modelo 2
    renderHandlebars( $( '#d-section-maps' ), 'contact/section-contact-v1', $( '.d-append-maps' ), createMaps );

    //    // Este bloco só sera carregado no modelo 2 de bloco de contato
    //    renderHandlebars( $( '#d-section-contact' ), 'contact/section-contact-v2', $( '.d-append-contact' ) );
  }
})( jQuery );
