( function( $ ) {

  // Slide de depoimentos no mobile
  //if ( $( '.m-testimony-slider' ).length ) {
  //  var mTestimonySlider = new Swiper('.m-testimony-slider', {
  //    speed: 400,
  //    spaceBetween: 10,
  //    pagination: $( '.m-testimony-pagination' ),
  //    paginationClickable: true
  //  });
  //}

	//if ( $( '.d-testimony-slider' ).length ) {
	//	var dTestimonySlider = new Swiper('.d-testimony-slider', {
	//		speed: 400,
	//		spaceBetween: 10,
	//		slidesPerView: 3,
	//		pagination: $( '.d-testimony-pagination' ),
	//		paginationClickable: true
	//	});
	//}

})( jQuery );