( function( $ ) {

  /*
  * Abre e fecha o submenu que fica oculto
  */
  $( document ).on( 'click', '.menu-icon-wrapper, .close-icon-wrapper, .nav-mobile-wrapper .links-sections', function() {
    $( '.nav-mobile-wrapper' ).toggleClass( 'is-open' );
  });

  $( document ).on( 'click', '.links-sections', function( event ) {
    event.preventDefault();

    var thisElement = $( this ),
        elementTarget = $( $( this ).attr( 'href' ) ),
        prevElement = $( '.is-showing' ),
        action = $( this ).attr( 'href' ) != '#m-principal' ? 'addClass': 'removeClass';

    if ( Math.abs( myScroll.y ) > 0 ) {
      // Faz o container do iScroll voltar para o marco 0 antes da troca dos blocos de conteúdo
      myScroll.scrollTo(0, 0, 1000, IScroll.utils.ease.circular);

      alterIsActive();

      // Apenas troca de tela depois que a animação de scroll já terminou
      setTimeout( function(){
        checkItemActive();

        $( '.section-header' )[action]( 'header-internal' );
      }, 1000 );
    }

    else {
      checkItemActive();
      alterIsActive();
      $( '.section-header' )[action]( 'header-internal' );
    }

    // Função que cria a transição entre os blocos
    function checkItemActive() {
      if ( elementTarget.hasClass( 'is-showing' ) ) {
        return false;
      }

      else if ( elementTarget.hasClass( 'is-hidden' ) ) {
        elementTarget.velocity( 'reverse' ).removeClass( 'is-hidden' ).addClass( 'is-showing' );
        prevElement.velocity( 'reverse' ).removeClass( 'is-showing' ).addClass( 'is-hidden' );
      }

      else {
        prevElement.velocity({
          right: "200%"
        }).removeClass( 'is-showing' ).addClass( 'is-hidden' );

        $( elementTarget ).velocity({
          right: 0
        }).addClass( 'is-showing' );
      }
    }

    function alterIsActive() {
      // As classes is-showing e is-hidden servem para indicar qual a direção da animação
      $( '.icon-footer-wrapper' ).removeClass( 'is-active' );

      $( thisElement ).parent().addClass( 'is-active' );
    }

  });

})( jQuery );