( function( $ ) {

  /* Detect Safari */
  if (navigator.userAgent.match(/AppleWebKit/) && ! navigator.userAgent.match(/Chrome/)) {
    /* Do something for Safari */
    $( 'body' ).addClass( 'is-safari' );
  }

  var IE = (function () {
    "use strict";

    var ret, isTheBrowser,
        actualVersion,
        jscriptMap, jscriptVersion;

    isTheBrowser = false;
    jscriptMap = {
      "5.5": "5.5",
      "5.6": "6",
      "5.7": "7",
      "5.8": "8",
      "9": "9",
      "10": "10"
    };
    jscriptVersion = new Function("/*@cc_on return @_jscript_version; @*/")();

    if (jscriptVersion !== undefined) {
      isTheBrowser = true;
      actualVersion = jscriptMap[jscriptVersion];
    }

    ret = {
      isTheBrowser: isTheBrowser,
      actualVersion: actualVersion
    };

    return ret;
  }());

  if ( IE.isTheBrowser || (/Edge\/12./i.test(navigator.userAgent)) )
    $( 'body' ).addClass( 'is-IE-10' );

})( jQuery );
