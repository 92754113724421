( function( $ ) {

  $( document ).on( 'click', '.open-webmail, .icon-close-webmail', function( event ) {
    event.preventDefault();

    $( '.section-webmail-wrapper' ).toggleClass( 'webmail-is-open' );
  })

  $( document ).on( 'click', '.sw-send-form', function( event ) {
    event.preventDefault();

    var formData = $( this ).parents( '.send-webmail' );

    if ( formData.valid() ) {
      formData[0].submit()

      formData[0].reset();

      if ( $( window ).width() >= 1200 )
        $( '.desk-webmail' ).toggleClass( 'webmail-is-open' );

      if ( $( '.section-webmail-wrapper' ).hasClass( 'webmail-is-open' ) )
        $( '.section-webmail-wrapper' ).removeClass( 'webmail-is-open' );
    }
    else {
      if ( $( '.error' ).parent().find( 'span' ).length == 0 )
        $( '.error' ).parent().append( $( "<span></span>" ).addClass( 'invalid' ) );

      return false;
    }
  });

})( jQuery );
